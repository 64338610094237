import './Styles/Index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import NavBar from './Utilities/NavBar/NavBar';
import Career from './Career/Career';
import LanguageCorner from './LanguageCorner/LanguageCorner';
import ScrollToTop from './Utilities/ScrollToTop';
import Footer from './Utilities/Footer/Footer';
import Contact from './Contact/Contact';
import WhyIPaintLanguageBooks from './Blogs/LanguageCorner/WhyIPaintLanguageBooks';
import firebaseConfig from './firebaseConfig';
import firebase from 'firebase/compat/app';
import BrokenLinkPage from './Utilities/BrokenLinkPage/BrokenLinkPage';
import {Helmet} from 'react-helmet';
firebase.initializeApp(firebaseConfig);
function App() {
  return (
      <Router>
        <div className='App'>
          <Helmet>
          <title>Hartaj S Trehan</title>
          <meta name='description' content='UI/UX Designer & Web/App Developer.' data-react-helmet="true"/>
          <meta name="theme-color" content="#141414" data-react-helmet="true"/>
          <meta name='keywords' content='Webdesigner, Website designer, How to make a website, webdevelopers mumbai, Web developer, how to build a website, webdeveloper, best, Mumbai ,Hartaj, Singh, Trehan' data-react-helmet="true"/>
          <meta name="apple-mobile-web-app-capable" content="yes" data-react-helmet="true"/>
          <meta name="mobile-web-app-capable" content="yes" data-react-helmet="true"/>
          <meta name="apple-mobile-web-app-title" content='Hartaj S Trehan' data-react-helmet="true"/>
          
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" data-react-helmet="true"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" data-react-helmet="true"/>
          <link rel="manifest" href="/site.webmanifest" data-react-helmet="true"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#62BA46" data-react-helmet="true"/>

          <meta property="og:url" content="https://hartajtrehan.com/" data-react-helmet="true"/>
          <meta property="og:type" content="website" data-react-helmet="true"/>
          <meta property="og:title" content="Hartaj S Trehan" data-react-helmet="true"/>
          <meta property="og:description" content="UI/UX Designer & Web/App Developer." data-react-helmet="true"/>
          <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2FhstShareImage.png?alt=media&token=1f040971-eddf-4210-8a9c-8012437d5c11" data-react-helmet="true"/>

          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
          <meta property="twitter:domain" content="hartajtrehan.com"/>
          <meta property="twitter:url" content="https://hartajtrehan.com/"/>
          <meta name="twitter:title" content="Hartaj S Trehan" data-react-helmet="true"/>
          <meta name="twitter:description" content="UI/UX Designer & Web/App Developer" data-react-helmet="true"/>
          <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2FhstShareImage.png?alt=media&token=1f040971-eddf-4210-8a9c-8012437d5c11" data-react-helmet="true"/>
          <meta name="twitter:image:alt" content="logo" data-react-helmet="true" />
          
          <meta name="msapplication-TileColor" content="#141414"/>
          </Helmet>
        <NavBar/>
        <ScrollToTop>
          <Routes>
            <Route path="/" exact Component={Home}/>
            <Route path="/work" exact Component={Career}/>
            <Route path="/languages" exact Component={LanguageCorner}/>
            <Route path="/contact" exact Component={Contact}/>
            <Route path='/languages/painting-language-notebooks-covers' exact Component={WhyIPaintLanguageBooks}/>
            <Route path="*" exact Component={BrokenLinkPage}/>
          </Routes>
        </ScrollToTop>
        <Footer/>
        </div>
      </Router>
  );
}

export default App;
