import React, { useEffect, useState, useRef } from 'react'
import './css/NavBar.css';
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import SchemaIcon from '@mui/icons-material/Schema';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
//import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RocketIcon from '@mui/icons-material/Rocket';
import StyleIcon from '@mui/icons-material/Style';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import clickSound from '../../Assets/clickSound.mp3'
function NavBar() {
  const [value,setValue] = useState(1);
  const [displayMobNavBar,setDisplayMobNavBar] = useState('none');
  const [mobNavToggler,setMobNavToggler] = useState('|||');



  useEffect(()=> {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  },[]);
  const menuRef = useRef(null);
  const handleClickOutside = (e) => {
    if (!menuRef.current.contains(e.target)) {
      setDisplayMobNavBar('none');
      setMobNavToggler('|||');
    }
  }


  useEffect(()=> {
    if(value % 2 === 0) {play()}
    
  },[value]);
  function play() {
   // new Audio(clickSound).play()
  }

  function handleMobNavOpen() {
    if (displayMobNavBar === 'flex') {
      setDisplayMobNavBar('none');
      setMobNavToggler('|||');
    } else {
      setDisplayMobNavBar('flex');
      setMobNavToggler('X');
    }
  }

  return (<>
    <nav className='NavBar'>
        
        <Link className='Nav-title' to="/">Hartaj</Link>
        <Link className='Nav-tab m-l-0' to="/"> <HomeIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/> <p>Home</p></Link>
        <Link className='Nav-tab Nav-disabled-tab' to="/"><BubbleChartIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Think Tank</p></Link>
        <Link className='Nav-tab' to="/work"> <WorkIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Work</p></Link>
        <Link className='Nav-tab' to="/"><SchemaIcon style={{fontSize:'1rem',marginRight:'7px'}}/> 
        
          <p>Blogs</p>
          <ui className='Nav-subtabs'>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><QrCodeScannerIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Entrepreneur’s Logs</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><SatelliteAltIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Aerospace Hub</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><DownhillSkiingIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Travel &amp; Adventure</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link' to="/languages"><NoiseControlOffIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Language Corner</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><ColorLensIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Art Gallery</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><LocalFireDepartmentIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/><p>Peace &amp; Conflicts</p></Link></li>
            <li className='Nav-subtab' muted="muted" onMouseEnter={() => setValue(value+1)} style={{borderRadius:'0 0 10px 10px'}}><Link className='Nav-subtab-link Nav-disabled-subtab'><BubbleChartIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Think Tank</p></Link></li>
          </ui>
        
        </Link>
        <Link className='Nav-tab' to="/contact"><ConnectWithoutContactIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Contact</p></Link>
    </nav>
    <nav className='NavBar-mobile' ref={menuRef}>
      <button className='NavBar-mobile-button' onClick={handleMobNavOpen}>{mobNavToggler}</button>
        <ui className='Nav-mobile-subtabs' style={{display:displayMobNavBar}}>
        <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link'to="/"><HomeIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Home</p></Link></li>
        <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link' to="/work"><WorkIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Work</p></Link></li>
        
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><QrCodeScannerIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Entrepreneur’s Logs</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><SatelliteAltIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Aerospace Hub</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><DownhillSkiingIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Travel &amp; Adventure</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link' to="/languages"><NoiseControlOffIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Language Corner</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><ColorLensIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Art Gallery</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><LocalFireDepartmentIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Peace &amp; Conflicts</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)}><Link className='Nav-subtab-link Nav-disabled-subtab'><BubbleChartIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Think Tank</p></Link></li>
          <li className='Nav-mobile-subtab' muted="muted" onMouseEnter={() => setValue(value+1)} style={{borderRadius:'0 0 10px 10px'}}><Link className='Nav-subtab-link' to="/contact"><ConnectWithoutContactIcon style={{fontSize:'1.1rem',marginRight:'10px'}}/><p>Contact</p></Link></li>
        </ui>
    </nav>
    </>
  )
}

export default NavBar

//https://mui.com/material-ui/material-icons/?query=house ICONS