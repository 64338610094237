import React from 'react'
import './css/BrokenLinkPage.css';

function BrokenLinkPage() {
  return (
    <div className='BrokenLinkPage-container'>
        <h1>404 Page Not Found</h1>
    </div>
  )
}

export default BrokenLinkPage