import React, { useEffect, useRef, useState } from 'react'
import './css/Home.css';
import Helipad from '../Assets/helipad.png';
import NavBar from '../Utilities/NavBar/NavBar';
import PickVibe from '../Components/PickVibe';
import { Link } from 'react-router-dom';
import Bucketlist from '../Components/Bucketlist';
import SnackBar from '../Utilities/SnackBar/SnackBar';
import {getAnalytics, logEvent} from "firebase/analytics";
import { Helmet } from 'react-helmet';
function Home() {
    const analytics = getAnalytics();
    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            // My actual effect logic...
            logEvent(analytics, 'Ziyaret', { name: 'Home Page'})
          }
    },[])
  return (
    <div className='Home-container'>
          <Helmet>
            <title>Hartaj S Trehan</title>
            <meta name='description' content='UI/UX Designer & Web/App Developer.' data-react-helmet="true"/>
            <meta name="theme-color" content="#141414" data-react-helmet="true"/>
            <meta name='keywords' content='Webdesigner, Website designer, How to make a website, webdevelopers mumbai, Web developer, how to build a website, webdeveloper, best, Mumbai ,Hartaj, Singh, Trehan' data-react-helmet="true"/>  
            <link rel='canonical' href='/'/>
          </Helmet>
        <SnackBar/>
        <h1>HARTAJ SINGH TREHAN</h1>
        <div className='Home-subtitle'>
            <p className='fatter'>Entrepreneur</p>
            <p>UI/UX Designer</p>
            <p className='fatter'>Full Stack Developer</p>
            <p>Aerospace</p>
            <p className='fatter'>Explorer</p>
        </div>
        
        <div className='dashed-line'/>
        <div className='Home-hero'>
            <p className='Home-hero-title fatter'>Welcome to my<br/>helipad</p>
            <img alt='green background image with a helicopters tailrotor' src={Helipad} className='Home-hero-img'/>
        </div>

        <div className='Home-switcher'>
            <div className="Home-switcher-item tab-border-right">
                <p className='Home-switcher-title'>Think Tank</p>
                <p className='Home-switcher-subtitle'>Intellectual Impressions</p>
            </div>
            <Link to='/work' className="Home-switcher-item">
                <p className='Home-switcher-title'>Work with me</p>
                <p className='Home-switcher-subtitle'>Innovation in vision</p>
            </Link>
        </div>

        <PickVibe/>
        <div className='Home-bucketlist'>
            <p className='Home-bucketlist-title'>Bucket List</p>
            <Bucketlist/>
        </div>
    </div>
  )
}

export default Home

//https://dev.to/finiam/infinite-looping-react-component-3135