import React, {useState} from 'react'
import './css/SnackBar.css';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
function SnackBar() {
  const [display, setDisplay] = useState('flex')

  function handleDisplay() {
    setDisplay('none')
  }
  return (
    <div className='SnackBar' style={{display:display}}>
      <div className='SnackBar-left'>
        Want a website for yourself?
        <Link className='SnackBar-action' to='/contact'>Click Here</Link>
      </div>
      <div className='SnackBar-right'>
        <button onClick={handleDisplay} className='SnackBar-closeButton'><CloseIcon/></button>
      </div>
    </div>
  )
}

export default SnackBar