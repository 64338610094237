import React, { useEffect, useRef } from 'react'
import {getAnalytics, logEvent} from "firebase/analytics";
import './css/Career.css';
import CareerBanner from '../Assets/careerBanner.png';
import OrchardBrands from '../Assets/orchardBrands.png';
import OptimX from '../Assets/optimx.png';
import AdventureChase from '../Assets/adventureChase.png';
import { Link } from 'react-router-dom';
import SnackBar from '../Utilities/SnackBar/SnackBar';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
function Career() {
const navigate = useNavigate();
let Techsparks = 'https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2Ftechsparks.png?alt=media&token=549ff464-4ba7-46ca-960c-c1c69dbeed27';
let Techsparks2 = 'https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2Ftechsparks2.png?alt=media&token=fb8cd8a7-e775-48f1-b569-50fc28f5e8a5';
const analytics = getAnalytics();
const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            // My actual effect logic...
            logEvent(analytics, 'Ziyaret', { name: 'Work Page'})
          }
    },[])  

const reportCuriousAboutQuote = (e) => {
    e.preventDefault();
    logEvent(analytics, 'Button_Pressed', { name: 'Curious_About_Quote'})
    navigate('/contact')
}
return (
    <div className='Career-container'>
        <Helmet>
            <title>Work | Hartaj</title>
            <meta name='description' content="Hartaj's CV & Work experience"/>
            <link rel='canonical' href='/work'/>
            
            <meta property="og:url" content={`https://www.hartajtrehan.com/work`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Work | Hartaj"/>
            <meta property="og:description" content="Hartaj's CV & Work experience"/>
            <meta property="og:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>

        
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="hartajtrehan.com"/>
            <meta property="twitter:url" content={`https://www.hartajtrehan.com/work`}/>
            <meta name="twitter:title" content="Work | Hartaj"/>
            <meta name="twitter:description" content="Hartaj's CV & Work experience"/>
            <meta name="twitter:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>
        </Helmet>
        <SnackBar/>
        <h1>Career Hub</h1>
        <div className='Career-hero'>
            <img alt='hartaj' src={CareerBanner} className='Career-hero-img'/>
        </div>
        <div className='Career-info-container'>
            <div className='Career-info-left'>
                <div className='Career-summary-container'>
                    <p className='Career-summary-title'>Executive summary</p>
                    <p className='Career-summary'>Experienced Co-Founder & Chief Design Officer of OptimX Sports with a proven track record in shaping the strategic vision and direction of a SaaS startup. Adept at cross-functional collaboration, I have successfully led initiatives in design, development, and operational efficiency. Committed to creating user-centric, scalable solutions that align with business goals.</p>
                </div>
                <div className='Career-education-container'>
                    <p className='Career-education-title'>Education</p>
                    <div className='Career-education'>
                        <p className='Career-education-year'>2013-2017</p>
                        <p className='Career-education-details'>MEng in Aerospace Engineering (Hons), The University of Manchester, UK<br/>Graduated on 11th July 2017</p>
                    </div>
                </div>
                <button className='Career-downloadButton' disabled>Download CV</button> 
                <Link to='https://www.linkedin.com/in/hartaj-trehan-101b6980/' className='Career-socialsButton' target='_blank'><LinkedInIcon style={{fontSize:'2rem'}}/></Link>
            </div>
            <div className='Career-info-right'>
                <p>Full<br/>Stack<br/>Developer</p>
            </div>
        </div>
        <div className='Career-photo-container'>
            <img alt="Hartaj at tech sparks" src={Techsparks2} class="Career-photo"/>
            <img alt="Hartaj at tech sparks" src={Techsparks} class="Career-photo"/>
        </div>
        <div className='Career-CV-section'>
            <div className='Career-CV'>
                <p className='Career-CV-section-label'>Work Experience</p>
                <p className='Career-CV-exp'>2021 - Present | Start Up | OptimX Sports</p>
                <p className='Career-CV-exp-info'>Co-Founder &amp; Chief Design Officer at OptimX Sports &#40;StartUp in USA&#41;. Remotely from Mumbai</p>
                <ul className='Career-CV-exp-list'>
                    <li className='Career-CV-exp-list-item'>Chicago based start-up, hosting 200&#43; sports teams on the OptimX Sports web platform.</li>
                    <li className='Career-CV-exp-list-item'>A website builder where a sports team can set up their website within just 30 minutes.</li>
                    <li className='Career-CV-exp-list-item'>Instrumental in steering the company towards profitability through innovative design strategies and operational optimisations.</li>
                    <li className='Career-CV-exp-list-item'>Spearheaded the development of the company&#39;s vision and set strategic directions in line with scalable objectives.</li>
                    <li className='Career-CV-exp-list-item'>Collaborated cross-functionally with Engineering, Operations, and Marketing teams to ensure cohesive and effective operations.</li>
                    <li className='Career-CV-exp-list-item'>Orchestrated the entire product lifecycle, from conceptual design to successful market launch, ensuring a seamless user experience.</li>
                    <li className='Career-CV-exp-list-item'>Incorporated customer needs and feedback in to the product development process, resulting in a user-friendly and market-responsive platform.</li>
                    <li className='Career-CV-exp-list-item'>Utilised ReactJS for both front-end and back-end product design and development, contributing to a robust and efficient tech stack.</li>
                    <li className='Career-CV-exp-list-item'><a href='https://www.optimxsports.com' target='_blank'>www.optimxsports.com</a></li>
                </ul>

                <p className='Career-CV-exp'>2018 - Present</p>
                <p className='Career-CV-exp-info'>Tréhz - Self-Employed</p>
                <ul className='Career-CV-exp-list'>
                    <li className='Career-CV-exp-list-item'>Design &amp; Developed websites for clients.</li>
                    <li className='Career-CV-exp-list-item'>Developed and marketed 6 apps on the Apple App Store.</li>
                    <li className='Career-CV-exp-list-item'>Developed www.adventure-chase.com using React js. An online Travel Magazine platform.</li>
                </ul>

                <p className='Career-CV-section-label m-t-50'>PREVIOUS WORK</p>
                <p className='Career-CV-exp'>Website Design and Development</p>
                <ul className='Career-CV-portfolio'>
                    <li className='Career-CV-portfolio-item'>
                        <Link className='Career-CV-portfolio-item-link' target="_blank" to='http://www.orchardbrands.in'>
                            <img className='Career-CV-portfolio-img' alt='orchard brands' src={OrchardBrands}/>
                            <div className='Career-CV-portfolio-text-box'><p className='Career-CV-portfolio-text'>Orchard brands</p></div>
                        </Link>
                    </li>
                    <li className='Career-CV-portfolio-item'>
                        <Link className='Career-CV-portfolio-item-link' target="_blank" to='https://adventure-chase.com'>
                            <img className='Career-CV-portfolio-img' alt='orchard brands' src={AdventureChase}/>
                            <div className='Career-CV-portfolio-text-box'><p className='Career-CV-portfolio-text'>Adventure Chase</p></div>
                        </Link>
                    </li>
                    <li className='Career-CV-portfolio-item'>
                        <Link className='Career-CV-portfolio-item-link' target="_blank" to='https://optimxsports.com'>
                            <img className='Career-CV-portfolio-img' alt='orchard brands' src={OptimX}/>
                            <div className='Career-CV-portfolio-text-box'><p className='Career-CV-portfolio-text'>OptimX Sports</p></div>
                        </Link>
                    </li>
                    <li className='Career-CV-portfolio-item'>
                        <div className='Career-CV-portfolio-text-box2'>
                            <button className='Career-CV-portfolio-button' onClick={reportCuriousAboutQuote} >Get a Quote</button>
                            <p className='Career-CV-portfolio-text2'>Inquire Hartaj for Website development or UI/UX project</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        
    </div>
  )
}

export default Career