import React from 'react'
import './css/Footer.css';
import optimxlogo from '../../Assets/optimxlogo.png';
import ac from '../../Assets/ac.png';
import trehz from '../../Assets/trehz.png';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import SchemaIcon from '@mui/icons-material/Schema';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
function Footer() {
  return (
    <div className='Footer-container'>
        <div className='Footer-box'>
            <div className='Footer-sides'>
                <Link className='Footer-tab m-l-0' to="/"> <HomeIcon style={{fontSize:'1.1rem',marginRight:'6px'}}/> <p>Home</p></Link>
                <Link className='Footer-tab' to="/work"> <WorkIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Work</p></Link>
            </div>
            <div className='Footer-center'>
                <div className='Footer-center-top'>
                    <a href="https://www.optimxsports.com" target="_blank"><img className='Footer-center-logos' alt='logo' src={optimxlogo}/></a>
                    <a href="https://www.adventure-chase.com" target="_blank"><img className='Footer-center-logos m-l-20 m-r-20' alt='adventure chase logo' src={ac}/></a>
                    <img className='Footer-center-logos' alt='trehz logo' src={trehz}/>
                </div>
                <p className='Footer-center-bottom'>&copy; copyright hartaj 2024</p>
            </div>
            <div className='Footer-sides'>
                <Link className='Footer-tab' to="/"><SchemaIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Blogs</p></Link>
                <Link className='Footer-tab' to="/contact"><ConnectWithoutContactIcon style={{fontSize:'1rem',marginRight:'7px'}}/> <p>Contact</p></Link>
            </div>
        </div>
    </div>
  )
}

export default Footer