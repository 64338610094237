import React from 'react'
import './css/PickVibe.css';
import { Link } from 'react-router-dom';

function PickVibe() {
  return (
    <div className='PickVibe-container'>
        <h2 className='PickVibe-title'>Pick Your Vibe</h2>
        <div className='PickVibe-vibes-container'>
            <div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 1 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Entrepreneur’s Logs</p>
                </div>
                </div>
            </div>
            <div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 2 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Aerospace<br/>Hub</p>
                </div>
                </div>
            </div><div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 3 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Travel & Adventure</p>
                </div>
                </div>
            </div><div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 4 </div>
                <Link to="/languages" className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Language<br/>Corner</p>
                </div>
                </Link>
            </div><div className='PickVibe-vibe m-r-'>
                <div className='PickVibe-Vibe-no'> 5 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Art<br/>Gallery</p>
                </div>
                </div>
            </div><div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 6 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>Peace & Conflicts</p>
                </div>
                </div>
            </div><div className='PickVibe-vibe'>
                <div className='PickVibe-Vibe-no'> 7 </div>
                <div className='PickVibe-Vibe-box'>
                <div className='PickVibe-vibe-bubble'></div>
                <div className='PickVibe-vibe-title-box'>
                    <p className='PickVibe-vibe-title'>The Indic Manifesto</p>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PickVibe