import React, { useEffect, useRef, useState } from 'react'
import {getAnalytics, logEvent} from "firebase/analytics";
import './css/Blogs.css';
import azer1 from '../../Assets/azerbaijani1.jpg';
import azer2 from '../../Assets/azerbaijani2.jpg';
import farsi1 from '../../Assets/farsi1.jpg';
import farsi2 from '../../Assets/farsi2.jpg';
import farsi3 from '../../Assets/farsi3.jpg';
import turkish from '../../Assets/turkish.jpg';
import armenian from '../../Assets/armenian.jpg';
import russian from '../../Assets/russian.jpg';
import german from '../../Assets/german.jpg';
import { Tooltip } from '@mui/material';
import SnackBar from '../../Utilities/SnackBar/SnackBar';
import { Helmet } from 'react-helmet';
function WhyIPaintLanguageBooks() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(true)


  const analytics = getAnalytics();
  const initialized = useRef(false)
    
  useEffect(() => {
    if (!initialized.current) {
        initialized.current = true
        // My actual effect logic...
        logEvent(analytics, 'Ziyaret', { name: 'Language_Corner'})
      }
},[]) 

  useEffect (() => {
    checkConditions();
  }, [name,email,message]);


  function checkConditions() {
    console.log('checking...', name,email,message)
    if (message !== '') {
      setDisableSubmit(false)
      console.log('condisitons met')
    } else {setDisableSubmit(true)}
  }

  return (
    <div className='Blogs-container'>
        <Helmet>
            <title>Painting language notebook's covers</title>
            <meta name='description' content="I read in a Turkish book that in medieval times, Sultans and Padshahs from India used to invite painters from Turkey to paint their kingdom's accounting books in traditional Persian miniature styles since nothing was more valuable to paint than books."/>
            <link rel='canonical' href='/painting-language-notebooks-covers'/>
            
            <meta property="og:url" content={`https://www.hartajtrehan.com/painting-language-notebooks-covers`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Painting language notebook's covers"/>
            <meta property="og:description" content="I read in a Turkish book that in medieval times, Sultans and Padshahs from India used to invite painters from Turkey to paint their kingdom's accounting books in traditional Persian miniature styles since nothing was more valuable to paint than books."/>
            <meta property="og:image" itemprop="image" content="https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2FlangPaintingsShareImage.jpg?alt=media&token=30d33329-b170-4777-b4bc-fefaabfe4a55"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="hartajtrehan.com"/>
            <meta property="twitter:url" content={`https://www.hartajtrehan.com/painting-language-notebooks-covers`}/>
            <meta name="twitter:title" content="Painting language notebook's covers"/>
            <meta name="twitter:description" content="I read in a Turkish book that in medieval times, Sultans and Padshahs from India used to invite painters from Turkey to paint their kingdom's accounting books in traditional Persian miniature styles since nothing was more valuable to paint than books."/>
            <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/webster-7020d.appspot.com/o/Hartaj%2FlangPaintingsShareImage.jpg?alt=media&token=30d33329-b170-4777-b4bc-fefaabfe4a55"/>
        </Helmet>
        <SnackBar/>
        <div className='Blogs-header'>
            <h1 className='Blogs-title'>Painting language notebook's covers</h1>
            <div className='Blogs-info'>
                <div className='Blogs-info-left'>
                    <p className='Blogs-author'><span className='Blogs-author-dot'>• </span> Hartaj Singh Trehan</p>
                    <p className='Blogs-date'>18/04/2023</p>
                </div>
            </div>
        </div>
        <div className='Blogs-body'>
            <div className='Blogs-body-canvas'>
                <h3 className='Blogs-body-subtitle'>Inspiration</h3>
                <p className='Blogs-body-para'>
                    I read in a Turkish book that in medieval times, Sultans and Padshahs from India used to invite painters from Turkey to paint their kingdom's accounting books in traditional Persian miniature styles since nothing was more valuable to paint than books. The author also mentioned that Turkish painters were skilled in painting entire palace walls and roofs like the ones found in Europe, but the Indian Padshahs offered much more money just to paint their poetry and accounting books. Such was their respect for Vidhya (knowledge).
                    <br/><br/>
                    Inspired by this - since I am also an artist - I painted many of my target language's notebook covers (displayed below) in their respective traditional painting styles. I believe in judging a book by its cover.
                </p>
                <div className='Blogs-body-3rowimages'>
                    <Tooltip title="Azerbaijani Notebook"><img alt='notebook painting' src={azer1} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Turkish & Farsi Notebook"><img alt='notebook painting' src={farsi1} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Turkish Notebook"><img alt='notebook painting' src={turkish} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Farsi Notebook"><img alt='notebook painting' src={farsi2} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Armenian Notebook"><img alt='notebook painting' src={armenian} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="German Notebook"><img alt='notebook painting' src={german} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Russian Notebook"><img alt='notebook painting' src={russian} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Back Cover of Farsi Notebook"><img alt='notebook painting' src={farsi3} className='Blogs-body-3rowimage rad10'/></Tooltip>
                    <Tooltip title="Back Cover of Azerbaijani Notebook"><img alt='notebook painting' src={azer2} className='Blogs-body-3rowimage rad10'/></Tooltip>
                </div>
                
            </div>
        </div>
        
        <div class="Blogs-feedback">
        <h3 className='Blogs-feedback-title'>Thoughts?</h3>
        <form class="Blogs-feedback-form" action="https://formsubmit.co/3447947aecb92d910c5a65da1efa57c2" method="POST">
            <input class="Blogs-feedback-textfield" name="name" type="text" placeholder="Name (optional)" onChange={e => setName(e.target.value)}/>
            <input class="Blogs-feedback-textfield" name="email" type="email" placeholder="Email (optional)" onChange={e=> setEmail(e.target.value)}/>
            <textarea class="Blogs-feedback-textarea" name="message" type="text" placeholder="What are your thoughts on this?" onChange={e=> setMessage(e.target.value)}></textarea>
            <button class="Blogs-feedback-button" type="submit" disabled={disableSubmit}>Submit</button>
        </form>
        </div>
    </div>
  )
}

export default WhyIPaintLanguageBooks