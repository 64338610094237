// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA6Eb-c5uQhq70SOyxkRPniQpb1jkSQUkg",
  authDomain: "webster-7020d.firebaseapp.com",
  projectId: "webster-7020d",
  storageBucket: "webster-7020d.appspot.com",
  messagingSenderId: "141738449632",
  appId: "1:141738449632:web:031024363d6f1b4df54e85",
  measurementId: "G-PC7VB9MP0H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default firebaseConfig;