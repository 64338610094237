import React, { useState } from 'react'
import './css/Bucketlist.css';
function Bucketlist() {

    const [list, setList] = useState([
        {
            listItem: "Build a million dollar start-up from scratch",
            progress: 100
        },
        {
            listItem: "Exit from the start-up",
            progress: 0
        },
        {
            listItem: "Build a personal website",
            progress: 100
        },
        {
            listItem: "Travel to Azerbaijan",
            progress: 100
        },
        {
            listItem: "Travel to all the -stans",
            progress: 16
        },
        {
            listItem: "Travel to Russia",
            progress: 0
        },
        {
            listItem: "Learn Azerbaijani",
            progress: 50
        },
        {
            listItem: "Learn Turkish",
            progress: 60
        },
        {
            listItem: "Learn Farsi",
            progress: 80
        },
        {
            listItem: "Learn Russian",
            progress: 20
        },
        {
            listItem: "Learn French",
            progress: 10
        },
        
    ])

    function getColor(progress) {
        let color;
        switch (true) {
          case progress < 20:
            color = '#FD0F1A';
            break;
          case progress < 50:
            color = '#FFB71B';
            break;
          case progress < 80:
            color = '#F0FFB1';
            break;
          case progress < 100:
            color = '#4DAFCC';
            break
          default:
            color = '#62BA46'//'#3EC18E'; 
            break;
        }
        return color;
      }
    
  return (
    <div className='Bucketlist-container'>
            <div className='Bucketlist-bubble'></div>
            <div className='Bucketlist-box'>
                <p className='Bucketlist-box-title'>&lt;BucketList i&#61;&#123;{list.length}&#125;&gt;</p>
                <div className='Bucketlist-box-scroller'>
                {
                    list.map(item => (
                        <div key={item.listItem}>
                        <p className='Bucketlist-item'>{item.listItem}</p>
                        <div className='progressBar'>
                            <div className='progressBar-progress' style={{width:item.progress+'%', backgroundColor:getColor(item.progress)}}></div>
                        </div>
                        </div>
                    ))
                }
                </div>
                <p className='Bucketlist-box-title'>&lt;/BucketList&gt;</p>
            </div>
    </div>
  )
}

export default Bucketlist