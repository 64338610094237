import React, { useEffect, useRef, useState } from 'react'
import {getAnalytics, logEvent} from "firebase/analytics";
import './css/Contact.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import optimxlogo from '../Assets/optimxlogo.png';
import ac from '../Assets/ac.png';
import trehz from '../Assets/trehz.png';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(true)
  const analytics = getAnalytics();
  const initialized = useRef(false)
  useEffect(() => {
      if (!initialized.current) {
          initialized.current = true
          // My actual effect logic...
          logEvent(analytics, 'Ziyaret', { name: 'Home Page'})
        }
  },[])

  useEffect (() => {
    checkConditions();
  }, [name,email,message]);

  function checkConditions() {
    console.log('checking...', name,email,message)
    if (name && email && message !== '') {
      setDisableSubmit(false)
      console.log('condisitons met')
    } else {setDisableSubmit(true)}
  }

  return (
    <div className='Contact-container'>
      <Helmet>
            <title>Contact Me</title>
            <meta name='description' content="Contact for web development or start up ideas"/>
            <link rel='canonical' href='/contact'/>
            
            <meta property="og:url" content={`https://www.hartajtrehan.com/contact`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Contact Me"/>
            <meta property="og:description" content="Contact for web development or start up ideas"/>
            <meta property="og:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>

        
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="hartajtrehan.com"/>
            <meta property="twitter:url" content={`https://www.hartajtrehan.com/contac or start up ideast`}/>
            <meta name="twitter:title" content="Contact Me"/>
            <meta name="twitter:description" content="Contact for web development"/>
            <meta name="twitter:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>
        </Helmet>
        <div className='Contact-box'>
          <div className='Contact-box-left'>
            <h1 className='Contact-box-left-title'>Lets <span className='Contact-box-left-title-yellow'>Talk</span></h1>
            <div className='Contact-box-left-logos'>
            <Link to='https://www.linkedin.com/in/hartaj-trehan-101b6980/' className='Contact-box-left-logo-container' target='_blank'><LinkedInIcon className='Contact-box-left-logo' style={{fontSize:'40px', margin:'0px 6px 0px 0px'}}/></Link>
            <Link to='https://www.instagram.com/adventure__chase/' className='Contact-box-left-logo-container' target='_blank'>  <InstagramIcon className='Contact-box-left-logo'style={{fontSize:'40px', margin:'0px'}}/></Link>
            <Link to='http://adventure-chase.com' className='Contact-box-left-logo-container' target='_blank'>  <img className='Contact-box-left-logo' alt="ac logo" src={ac}/></Link>
            <Link to='https://optimxsports.com' className='Contact-box-left-logo-container' target='_blank'>  <img className='Contact-box-left-logo' alt="ox logo" src={optimxlogo}/></Link>
            </div>
            <form class="Contact-box-form" action="https://formsubmit.co/3447947aecb92d910c5a65da1efa57c2" method="POST">
                  <input class="Contact-box-textfield" name="name" type="text" placeholder="Full name*" onChange={e => setName(e.target.value)}/>
                  <input class="Contact-box-textfield" name="email" type="email" placeholder="Email*" onChange={e=> setEmail(e.target.value)}/>
                  <textarea class="Contact-box-textarea" name="message" placeholder="How can I help you?*" onChange={e=> setMessage(e.target.value)}></textarea>
                  <button class="Contact-box-button" type="submit" disabled={disableSubmit}>Submit</button>
            </form>
          </div>
          <div className='Contact-box-right'>
            <div className='Contact-box-right-top'>
              <p className='Contact-box-right-top-label'>Commission Work</p>
              <img className='Contact-box-right-top-logo' alt="trehz logo" src={trehz}/>
            </div>
            <div className='Contact-box-right-bottom'>
              <p className='Contact-box-right-bottom-label'>Website/App Design &amp;<br/> Development</p>
              <p className='Contact-box-right-bottom-label2'>starting from $1000</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Contact