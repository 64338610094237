import React, { useEffect, useRef } from 'react'
import {getAnalytics, logEvent} from "firebase/analytics";
import './css/LanguageCorner.css';
import LangCorner from '../Assets/langCorner.png';
import LangCornerReduced from '../Assets/langCornerReduced.png'
import Ar from '../Assets/ar.png';
import Az from '../Assets/az.png';
import De from '../Assets/de.png';
import In from '../Assets/in.png';
import Ir from '../Assets/ir.png';
import Kz from '../Assets/kz.png';
import Ru from '../Assets/ru.png';
import Sa from '../Assets/sa.png';
import Tj from '../Assets/tj.png';
import Tr from '../Assets/tr.png';
import Uk from '../Assets/uk.png';
import azer1 from '../Assets/azerbaijani1.jpg';
import azer2 from '../Assets/azerbaijani2.jpg';
import farsi1 from '../Assets/farsi1.jpg';
import farsi2 from '../Assets/farsi2.jpg';
import farsi3 from '../Assets/farsi3.jpg';
import turkish from '../Assets/turkish.jpg';
import armenian from '../Assets/armenian.jpg';
import russian from '../Assets/russian.jpg';
import german from '../Assets/german.jpg';
import BoltIcon from '@mui/icons-material/Bolt';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function LanguageCorner() {
const analytics = getAnalytics();
const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            // My actual effect logic...
            logEvent(analytics, 'Ziyaret', { name: 'Language_Corner'})
          }
    },[])  
  return (
    <div className='LanguageCorner-container'>
        <Helmet>
            <title>Language Corner | Hartaj</title>
            <meta name='description' content="Language Corner is a blog for polyglots & language enthusiasts. Here I dive into linguistics, history and the science of language learning."/>
            <link rel='canonical' href='/languages'/>
            
            <meta property="og:url" content={`https://www.hartajtrehan.com/languages`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Language Corner | Hartaj"/>
            <meta property="og:description" content="Language Corner is a blog for polyglots & language enthusiasts. Here I dive into linguistics, history and the science of language learning."/>
            <meta property="og:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>

        
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="hartajtrehan.com"/>
            <meta property="twitter:url" content={`https://www.hartajtrehan.com/languages`}/>
            <meta name="twitter:title" content="Language Corner | Hartaj"/>
            <meta name="twitter:description" content="Language Corner is a blog for polyglots & language enthusiasts. Here I dive into linguistics, history and the science of language learning."/>
            <meta name="twitter:image" content="https://www.hartajtrehan.com/hstShareImage.png"/>
        </Helmet>
    <h1>LANGUAGE CORNER</h1>
    <div className='LanguageCorner-subtitle'>
        <img alt="Uk" className='LanguageCorner-flag' src={Uk}/>
        <img alt="In" className='LanguageCorner-flag' src={In}/>
        <img alt="Ar" className='LanguageCorner-flag' src={Ar}/>
        <img alt="Ir" className='LanguageCorner-flag' src={Ir}/>
        <img alt="Az" className='LanguageCorner-flag' src={Az}/>
        <img alt="De" className='LanguageCorner-flag' src={De}/>
        <img alt="Tj" className='LanguageCorner-flag' src={Tj}/>
        <img alt="Tr" className='LanguageCorner-flag' src={Tr}/>
        <img alt="Ru" className='LanguageCorner-flag' src={Ru}/>
        <img alt="Sa" className='LanguageCorner-flag' src={Sa}/>
        <img alt="Kz" className='LanguageCorner-flag' src={Kz}/>
    </div>
    
    <div className='dashed-line'/>
    <div className='LanguageCorner-hero'>
        <img alt='Language Corner' src={LangCorner} className='LanguageCorner-hero-img' style={{background:`${LangCornerReduced} no-repeat;`}}/>
    </div>
    
    <div className='LanguageCorner-summary-container'>
        <p className='LanguageCorner-summary-title'>Summary</p>
        
        <p className='LanguageCorner-summary-text'>
            I speak 7 languages, while I have attempted to learn 11 languages in my life. I claim to speak a language only when someone asks me to either describe the political landscape of my country or summarise my entire religion to them in the target language. Such topics usually tend to eventually come up whether I am talking to a friend or some shopkeeper in a country I am travelling in asks me about it.
            <br/><br/>
            The purpose of Language Corner is to discuss the history of language evolution, the science of language learning, and the unique aspects of each language/language family.
            <br/><br/>
            Since I am also an artist - I painted many of my target language's notebook covers (displayed below) in their respective traditional painting styles.
        </p>
    </div>

    <ul className='LanguageCorner-language-list'>
        <li className='LanguageCorner-language Lc-red'><Tooltip title="Azerbaijani Notebook"><img alt='notebook painting' src={azer1} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-blue'><Tooltip title="Turkish & Farsi Notebook"><img alt='notebook painting' src={farsi1} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-grey'><Tooltip title="Turkish Notebook"><img alt='notebook painting' src={turkish} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-brown'><Tooltip title="Farsi Notebook"><img alt='notebook painting' src={farsi2} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-brown'><Tooltip title="Armenian Notebook"><img alt='notebook painting' src={armenian} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-brown'><Tooltip title="German Notebook"><img alt='notebook painting' src={german} className='LanguageCorner-language-painting'/></Tooltip></li>
        <li className='LanguageCorner-language Lc-l-blue m-l-0'><Tooltip title="Russian Notebook"><img alt='notebook painting' src={russian} className='LanguageCorner-language-painting'/></Tooltip></li>
    </ul>
    <BoltIcon style={{fontSize:'2.5rem',color:'#FFB1CC',marginTop:'3vh'}}/>

    <div className='PolyglotLogs-container'>
        <h2 className='PolyglotLogs-title'>POLYGLOT LOGS</h2>
        <div className='PolyglotLogs-newLogs'>
            <Link to="/languages/painting-language-notebooks-covers" className='PolyglotLogs-newLogs-topleft'><p className='PolyglotLogs-newLogs-author'><span className='PolyglotLogs-newLogs-author-dot'>• </span> Hartaj Singh Trehan</p><h3 className='PolyglotLogs-newLogs-title'>Painting Language Notebooks Covers</h3><p className='PolyglotLogs-newLogs-subtitle'><span className='PolyglotLogs-newLogs-subtitle-letter'>I</span> painted many of my target language's notebook covers (displayed below) in their respective traditional painting styles.</p></Link>
            <div className='PolyglotLogs-newLogs-topright'><p className='PolyglotLogs-newLogs-author'><span className='PolyglotLogs-newLogs-author-dot'>• </span> Hartaj Singh Trehan</p><h3 className='PolyglotLogs-newLogs-title'>Painting Language Notebooks Covers</h3></div>
            <div className='PolyglotLogs-newLogs-bottom'>Hi</div>
            <div className='PolyglotLogs-newLogs-right'><p className='PolyglotLogs-newLogs-author'><span className='PolyglotLogs-newLogs-author-dot'>• </span> Hartaj Singh Trehan</p><h3 className='PolyglotLogs-newLogs-title'>Painting Language Notebooks Covers</h3><p className='PolyglotLogs-newLogs-subtitle'><span className='PolyglotLogs-newLogs-subtitle-letter'>I</span> painted many of my target language's notebook covers (displayed below)</p></div>
        </div>
    </div>
</div>
  )
}

export default LanguageCorner